/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */

const USER_FRIENDLY_COLUMN_ORDER = {
  guidance: [
    'Product Category',
    'Docket Number',
    'Center',
    'Issue Office Taxonomy',
    'Title',
    'Issue Date',
    'Open comment',
    'Comment Close Date',
    'Draft or Final',
    'Regulated Product Field',
    'Topics',
    'Topics Product',
    'Text',
    'Data Source',
    'Term Node Tid',
    'Changed Date',
    'Score Confidence'
  ],
  adcomm: [
    'Committee ID',
    'Meeting ID',
    'Meeting Start Date',
    'Meeting End Date',
    'Meeting Time',
    'Designation Type',
    'Application Number',
    'Category',
    'Submission',
    'Submission ID',
    'Active Ingredient',
    'Formulation',
    'Route',
    'Sponsor',
    'Approval Date',
    'Text',
    'Source',
    'Score Confidence'
  ],
  uk: [
    'Document Category',
    'Reference Number',
    'Guidance Programme',
    'Document Type',
    'Title',
    'Advice Programme',
    'Published',
    'Last Updated',
    'Text',
    'Source',
    'Score Confidence'
  ],
  ct: [
    'NCT ID',
    'Title',
    'Category',
    'Allocation',
    'Conditions',
    'Interventions',
    'Keywords',
    'Status',
    'Phase',
    'Sponsors',
    'Enrollment',
    'Start Date',
    'Completion Date',
    'Study Type',
    'Text',
    'Source',
    'Score Confidence'
  ],
  euctr: [
    'EUCT ID',
    'Title',
    'Category',
    'Allocation',
    'Conditions',
    'Interventions',
    'Phase',
    'Sponsors',
    'Enrollment',
    'Start Date',
    'Completion Date',
    'Text',
    'Score Confidence'
  ],
  us: [
    'Application Type',
    'Application Number',
    'Submission Type',
    'Submission ID',
    'Document Name',
    'Submission Date',
    'Active Ingredient',
    'Trade Name',
    'Formulation',
    'Route',
    'Sponsor',
    'Approval Pathway',
    'Text',
    'Source',
    'Fast Track',
    'Advisory Committee',
    'Monoclonal Antibody',
    'Data Source',
    'Label Type',
    'Office',
    'Pediatric Use',
    'Division',
    'RTOR',
    'Orphan Status',
    'BT Status',
    'Biosimilar',
    'Submission',
    'Submission Type Class',
    'Accelerated Approval',
    'Combination Product',
    'Score Confidence'
  ],
  eu: [
    'Application Number',
    'Document Type',
    'Submission Date',
    'Active Ingredient',
    'Trade Name',
    'Formulation',
    'Route',
    'Sponsor',
    'Monoclonal Antibody',
    'Text',
    'Source',
    'Data Source',
    'Biosimilar',
    'Advanced Therapy',
    'Orphan Medicine',
    'Conditional Approval',
    'Accelerated Assessment',
    'Exceptional Circumstances',
    'Combination Product',
    'Score Confidence'
  ],
  'us-devices': [
    'Designation Type',
    'Application Number',
    'Generic Name',
    'Document Type',
    'Submission Date',
    'Trade Name',
    'Sponsor',
    'Submission',
    'Text',
    'Source',
    'Score Confidence'
  ],
  'ema-pips': [
    'Decision Number',
    'Decision Date',
    'Active Ingredient',
    'Trade Name',
    'Formulations',
    'Route',
    'Therapeutic Area',
    'Monoclonal Antibody',
    'Text',
    'Source',
    'Score Confidence'
  ],
  'ema-guidance': [
    'EMA Document Number',
    'Document Title',
    'Document Type',
    'Status',
    'Last Published Date',
    'Last Updated Date',
    'Legal Effective Date',
    'Consultation Dates',
    'Text',
    'Score Confidence'
  ],
  'fda-written-request': [
    'Category',
    'Drug Name',
    'Title',
    'Determination Date',
    'Sponsor',
    'Text',
    'Source',
    'Score Confidence'
  ],
  ca: [
    'Application Number',
    'SCN',
    'Category',
    'Submission Approval Date',
    'Active Ingredient',
    'Brand Name',
    'Formulation',
    'Route',
    'Sponsor',
    'Current Status',
    'Monoclonal Antibody',
    'Text',
    'Source',
    'NOC with Condition',
    'Priority Review',
    'Score Confidence'
  ],
  chmp: [
    'Meeting Date',
    'Category Bucket',
    'Application Number',
    'First Published Date',
    'Last Updated Date',
    'Text',
    'Source',
    'Score Confidence'
  ],
  hpra: [
    'Application Number',
    'Submission Date',
    'Document Type',
    'Active Ingredient',
    'Trade Name',
    'Route',
    'Sponsor',
    'Formulation',
    'Text',
    'Source',
    'Supply Status',
    'Legal Status',
    'Advertising Status',
    'License Status',
    'Marketing Status',
    'Score Confidence'
  ],
  hma: [
    'Application Number',
    'Submission Date',
    'Document Type',
    'Active Ingredient',
    'Trade Name',
    'Formulation',
    'Reference State',
    'Concerned States',
    'Sponsor',
    'Marketing Status',
    'Score Confidence'
  ],
  'canada-guidance': [
    'Document Title',
    'Keywords',
    'Original file Url',
    'Created Date',
    'Issued Date',
    'Last Updated Date',
    'Score Confidence'
  ],
  'canada-consultation': [
    'Document Title',
    'Subjects',
    'Original file Url',
    'Document Type',
    'Created Date',
    'Start Date',
    'Score Confidence'
  ],
  jp: [
    'Application Number',
    'Document Type',
    'Submission Date',
    'Active Ingredient',
    'Trade Name',
    'Formulation',
    'Route',
    'Sponsor',
    'Text'
  ],
  'fda-letters': [
    'Letter Title',
    'Subject',
    'Company Name',
    'Issuing Office',
    'Document Type',
    'Source Page',
    'Original File Url',
    'Issued Date',
    'Created Date',
    'Text',
    'Score Confidence'
  ],
  'dd-tools': [
    'Project Number',
    'Project Name',
    'Document Type',
    'Stage',
    'Date',
    'Text',
    'Score Confidence'
  ],
  ema_orphan: [
    'Title',
    'Procedure Type/Status',
    'Approval Date',
    'Category',
    'Text',
    'Score Confidence'
  ],
  pdco: [
    'Title',
    'Category',
    'Text',
    'Score Confidence',
    'Status',
    'First Published Date',
    'Last Published Date'
  ],
  sec: ['Sponsors', 'CIK', 'Category', 'Date Filed', 'Text', 'Score Confidence']
};

export const COLUMN_ORDER = {
  guidance: [
    '_category',
    'field_docket_number',
    'field_center',
    'field_issuing_office_taxonomy',
    'title',
    'field_issue_datetime',
    'open_comment',
    'field_comment_close_date',
    'field_final_guidance_1',
    'field_regulated_product_field',
    'field_topics',
    'topics_product',
    'text',
    'data-source',
    'term_node_tid',
    'changed',
    'score_confidence'
  ],
  adcomm: [
    'committee_id',
    'joint_meeting_ids',
    'adcom_meeting_start_date',
    'adcom_meeting_end_date',
    'adcom_meeting_time',
    'designation_type',
    'application_number',
    'category',
    'submission',
    'submission_id',
    'active_ingredient',
    'formulation',
    'route',
    'sponsor',
    'approval_date',
    'text',
    'original-file-url',
    'score_confidence'
  ],
  uk: [
    'document_category',
    'reference_number',
    'guidance_type',
    'document_type',
    'title',
    'advice_programme',
    'published',
    'last_updated',
    'text',
    'original_file_url',
    'score_confidence'
  ],
  ct: [
    'nct_id',
    'brief_title',
    'category_bucket',
    'allocation',
    'conditions',
    'interventions',
    'keywords',
    'overall_status',
    'phase',
    'sponsors',
    'enrollment',
    'start_date',
    'completion_date',
    'study_type',
    'text',
    'original_file_url',
    'score_confidence'
  ],
  euctr: [
    'euct_id',
    'brief_title',
    'category',
    'allocation',
    'conditions',
    'interventions',
    'phase',
    'sponsors',
    'enrollment',
    'start_date',
    'completion_date',
    'text',
    'score_confidence'
  ],
  us: [
    'application_type',
    'application_number',
    'submission',
    'submission_id',
    'category',
    'approval_date',
    'active_ingredient',
    'trade_name',
    'formulation',
    'route',
    'sponsor',
    'approval-pathway',
    'text',
    'original-file-url',
    'fast_track',
    'advisory_committee',
    'monoclonal_antibody',
    'data_source',
    'label_type',
    'office',
    'pediatric_use',
    'division',
    'rtor',
    'orphan_status',
    'bt_status',
    'biosimilar',
    'submission',
    'submission_type_class',
    'accelerated_approval',
    'combination_product',
    'score_confidence'
  ],
  eu: [
    'application_number',
    'category',
    'approval_date',
    'active_ingredient',
    'trade_name',
    'formulation',
    'route',
    'sponsor',
    'monoclonal-antibody',
    'text',
    'original-file-url',
    'data_source',
    'biosimilar',
    'advanced_therapy',
    'orphan_medicine',
    'conditional_approval',
    'accelerated_assessment',
    'exceptional_circumstances',
    'combination_product',
    'score_confidence'
  ],
  'us-devices': [
    'designation_type',
    'application_number',
    'active_ingredient',
    'category',
    'approval_date',
    'trade_name',
    'sponsor',
    'submission',
    'text',
    'original-file-url',
    'score_confidence'
  ],
  'ema-pips': [
    'decision-number',
    'decision_date',
    'active_ingredient',
    'trade_name',
    'formulation',
    'route',
    'therapeutic-area',
    'monoclonal-antibody',
    'text',
    'original-file-url',
    'score_confidence'
  ],
  'ema-guidance': [
    'ema_document_no',
    'doc_title',
    'document_type',
    'status',
    'last_published_date',
    'last_updated_date',
    'legal_effective_date',
    'consultation_dates',
    'text',
    'score_confidence'
  ],
  'fda-written-request': [
    '_category',
    'drug_name',
    'title',
    'determination_date',
    'wr_sponsor',
    'text',
    'original-file-url',
    'score_confidence'
  ],
  'fda-letters': [
    'title',
    'subject',
    'company_name',
    'issuing_office',
    'document_type',
    'source_page',
    'original_file_url',
    'issue_date',
    'created_date',
    'text',
    'score_confidence'
  ],
  ca: [
    'vin-number',
    'scn',
    '_category',
    'submission-approval-date',
    'active-ingredient',
    'brand-name',
    'formulation',
    'route',
    'sponsor',
    'current-status',
    'monoclonal-antibody',
    'text',
    'original-file-url',
    'noc-with-condition',
    'priority-review',
    'score_confidence'
  ],
  chmp: [
    'chmp-meeting-id',
    'category-bucket',
    'chmp-number',
    'first-published',
    'last-updated',
    'text',
    'original-file-url',
    'score_confidence'
  ],
  hpra: [
    'product-number',
    'approval-date',
    '_category',
    'active-ingredient',
    'trade-name',
    'routes',
    'sponsor',
    'formulation',
    'text',
    'original-file-url',
    'supply-status',
    'legal-status',
    'advertising-status',
    'licence-status',
    'marketing-status',
    'score_confidence'
  ],
  hma: [
    'product_number',
    'approval_date',
    'category',
    'active_ingredient',
    'trade_name',
    'formulation',
    'rm_state',
    'cm_state',
    'sponsor',
    'marketing_status',
    'score_confidence'
  ],
  'canada-guidance': [
    'title',
    'keywords',
    'original_file_url',
    'created_at',
    'issued_date',
    'last_modified_date',
    'text',
    'score_confidence'
  ],
  'canada-consultation': [
    'title',
    'subjects',
    'original_file_url',
    'document_type',
    'start_date',
    'text',
    'score_confidence'
  ],
  jp: [
    'yj_code',
    '_category',
    'approval-date',
    'active-ingredient',
    'trade-name',
    'formulation',
    'route',
    'sponsor',
    'text',
    'score_confidence'
  ],
  'dd-tools': [
    'project_number',
    'title',
    'category_bucket',
    '_category',
    'stage_date',
    'text',
    'score_confidence'
  ],
  ema_orphan: [
    'document-title',
    'document-info',
    'approval-date',
    'category-bucket',
    'text',
    'score_confidence'
  ],
  pdco: [
    'original-file-name',
    'category-bucket',
    'text',
    'score_confidence',
    'status',
    'first-published',
    'last-updated'
  ],
  sec: ['companies', 'ciks', '_category', 'converted_filing_date', 'text', 'score_confidence']
};

const PDCO_CSV_COLUMN_ORDER = {
  'original-file-name': 'Title',
  'category-bucket': 'Category',
  score_confidence: 'Score Confidence',
  text: 'Text',
  status: 'Status',
  'first-published': 'First Published Date',
  'last-updated': 'Last Updated Date'
};

const isDate = str => {
  // Regular expression to match common date formats
  const dateRegex =
    /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})\.(\d{3})Z|(\d{2}\/\d{2}\/\d{4})T(\d{2}:\d{2}:\d{2})\.(\d{3})Z|(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{1,2}, \d{4} (\d{2}:\d{2}:\d{2})\.(\d{3})Z|(\d{2}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4})T(\d{2}:\d{2}:\d{2})\.(\d{3})Z$/;
  // Return true if the string matches any date format
  return dateRegex.test(str);
};

const cleanCell = (source, col, cellData) => {
  let newCellData = cellData;
  if (typeof cellData === 'string') {
    if (cellData?.length < 50 && isDate(cellData) && cellData.split('T').length > 1) {
      newCellData = cellData?.split('T')[0] || cellData;
    }
  } else {
    newCellData = cellData?.join(', ');
  }
  if (source === 'ema-guidance' && col === 'doc_title' && typeof newCellData === 'string') {
    newCellData = newCellData?.replace(/\(PDF\/.*B\)/, '');
  }
  if (col === 'score_confidence') {
    newCellData = newCellData?.replace('_', ' ') || '';
  }
  return newCellData;
};

const prepareRows = (columns, res, metadata, maxWidth, friendlyColumnNames, source) => {
  const entries = [...metadata, friendlyColumnNames];
  const widths = [];
  for (const row of res) {
    const _row = [];
    for (const col of columns) {
      if (col in row) {
        const cellData = cleanCell(source, col, row[col]);
        _row.push(cellData);
      } else {
        _row.push('');
      }
    }
    if (widths.length === 0) {
      for (let i = 0; i < columns.length; i += 1) {
        widths.push({ wch: Math.min(Math.max(_row[i].length, columns[i].length) + 4, maxWidth) });
      }
    }
    entries.push(_row);
  }
  return [entries, widths];
};

export const prepareData = (source, res, metadata = [[]], maxWidth = 25) => {
  const formatedMetadata = [];
  for (const line of metadata) {
    formatedMetadata.push([line]);
  }
  formatedMetadata.push(['']);
  return prepareRows(
    COLUMN_ORDER[source],
    res,
    formatedMetadata,
    maxWidth,
    USER_FRIENDLY_COLUMN_ORDER[source],
    source
  );
};

export const prepareCSVData = (source, res) => {
  const result = res.map(node => {
    return COLUMN_ORDER[source].reduce((runningValue, currentValue) => {
      return Object.assign(runningValue, {
        [source === 'pdco' ? PDCO_CSV_COLUMN_ORDER[currentValue] : currentValue]: cleanCell(
          source,
          currentValue,
          node?.[currentValue] ?? ''
        )
      });
    }, {});
  });
  return [result, USER_FRIENDLY_COLUMN_ORDER[source]];
};
